import { transferRowData as chargeDtlTransferRowData, INITIAL_CHARGE_DETAIL_COL_DEF } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailColumnDefinition";
import { transferRowData as creditNoteTransferRowData, INITIAL_CREDIT_NOTE_DETAIL_COL_DEF } from "presentation/constant/CreditNote/CreditNoteDetailColumnDefinition";
import { INITIAL_MAN_CHARGE_DTL_COL_DEF, transferRowData as manChargeTransferRowData } from "presentation/constant/ManualCharge/ManChargeDtlColumnDefinition";
import { INITIAL_NON_OPS_DTL_COL_DEF, transferRowData as nonOpsTransferRowData } from "presentation/constant/NonOps/NonOpsDtlColumnDefinition";
import { useChargeConfirmedVM } from "presentation/hook/ChargeConfirmed/useChargeConfirmedVM";
import { useChargeConfirmedTracked } from "presentation/store/ChargeConfirmed/ChargeConfirmedProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useCallback, useEffect, useMemo, useRef } from "react";


export const ChargeConfirmedRightTable: React.FC = () => {
    const [chargeConfirmedState] = useChargeConfirmedTracked();
    const chgConfVM = useChargeConfirmedVM();
    const gridRef = useRef<any>(null);

    const { currentRow, detailState, isShowRightPanel } = chargeConfirmedState;

    const handleClose = useCallback(() => {
        chgConfVM.onCloseDetail();
    }, [chgConfVM]);

    let cols: any[];
    let data: any[];

    if (currentRow) {
        if (currentRow.key === 'com.hph.hpsc.nbis.common.entity.charge.ManualChargeHeaderEntity') {
            cols = currentRow.chargeType === 'NO' ? INITIAL_NON_OPS_DTL_COL_DEF : INITIAL_MAN_CHARGE_DTL_COL_DEF;
            data = currentRow.chargeType === 'NO' ? nonOpsTransferRowData(detailState.details ?? []) : manChargeTransferRowData(detailState.details ?? []);
        } else if (currentRow.key === 'com.hph.hpsc.nbis.common.entity.charge.CreditChargeHeaderEntity') {
            cols = INITIAL_CREDIT_NOTE_DETAIL_COL_DEF;
            data = creditNoteTransferRowData(detailState.details ?? []);
        } else {
            cols = INITIAL_CHARGE_DETAIL_COL_DEF;
            data = chargeDtlTransferRowData(detailState.details ?? []);
        }
    } else {
        cols = INITIAL_CHARGE_DETAIL_COL_DEF;
        data = chargeDtlTransferRowData(detailState.details ?? []);
    }

    useEffect(() => {
        if (isShowRightPanel) {
            currentRow && chgConfVM.onDetailSearch(currentRow);
        }
    }, [chgConfVM, currentRow, isShowRightPanel])

    const memoChgConfRightTable = useMemo(() => {
        return (
            <>
                <NbisTable
                    id='charge-confirmed-detail-list-table'
                    headerLabel={'Charge Detail'}
                    isNewColumnSetting={true}
                    columns={cols?.slice()}
                    data={data}
                    headerActionButtons={[
                        {
                            id: 'onCloseButton',
                            icon: 'Icon-cross',
                            title: 'Close'
                        }
                    ]}
                    onCloseButton={handleClose}
                    showPaginator={false}
                    editable={false}
                    showHeaderIcons={true}
                    showAddIcon={false}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    selectionMode={false}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 125px)"
                    ref={gridRef}
                />

            </>
        );
    }, [cols, data, handleClose])


    return <><TableWrapper>{memoChgConfRightTable}</TableWrapper></>;
}

