import { ChargeDetailConstant } from "../ChargeDetailMaintenance/ChargeDetailConstant";
import { ChargeConstant } from "../ChargeHeader/ChargeConstant";

export const creditNoteHdrRequiredFieldList: string[] = [
    'currencyCode', 
    'customerCode',
];

export const creditNoteDtlRequiredFieldList: string[] = [
    'individualChargeQtyUom', 
    'creditAmount',
    'opsDate',
    'vatPercent',  
    'vatDays',  
    'vatDateType',  
    'taxCode',  
    'vatTariffType',
    'vatTariffCode',  
    'vatArticleStatementCode',  
    'reasonCode',   
];

export const CreditNoteConstant = {
    Header: {
        HDR_STATTE: "State",
        REQ_NO: "Req No",
        CHARGE_TYPE: ChargeConstant.Charge.CHARGE_TYPE,
        BILL_TO_COMPANY: "Bill To Company",
        CUSTOMER_CODE: "Customer Code",
        CURRENCY_CODE: "Currency",
        CONSORTIUM_CODE: ChargeConstant.Charge.CONSORTIUM_CODE,
        VESSEL_CODE: ChargeConstant.Charge.VESSEL_CODE,
        VOYAGE_CODE: ChargeConstant.Charge.VOYAGE_CODE,
        VESSEL_NAME: "Vessel Name",
        HANDING_TML: ChargeConstant.Charge.HANDLING_TML,
        DEPATURE_DATETIME: "Dep Date Time",
        PO_NO: "PO No.",
        YOUR_REF: "Your Ref",
        OUR_REF: "Our Ref",
        SL_VESSEL_CODE: ChargeConstant.Charge.SL_VESSEL_CODE,
        SL_IB_VOYAGE_CODE: ChargeConstant.Charge.SL_IB_VOYAGE_CODE,
        SL_OB_VOYAGE_CODE: ChargeConstant.Charge.SL_OB_VOYAGE_CODE,
        OPERATING_TML: ChargeConstant.ChargeHeader.OPERATING_TML,
        ORIGINAL_INVOICE_NO: "Original Invoice No.",
        CREDIT_NOTE_NO: ChargeConstant.Charge.CREDIT_NOTE_NO,
        DRAFT_CREDIT_NOTE_NO: ChargeConstant.Charge.DRAFT_CREDITE_NOTE_NO,
        CREDIT_CHARGE_NO: "Credit Charge No.",
        TOTAL_AMOUNT: "Total Amount",
        CREATED_BY: "Created by",
        CREATED_DATE: "Created Date",
        ISSUED_DATE: "Issued Date",
        STATE: "State",
        HDR_DESC1: "Description 1",
        HDR_DESC2: "Description 2",
        
        
        
        
    },
    Title: {
        CREDIT_NOTE: "Credit Note",
        CREDIT_NOTE_DETAIL: "Credit Note Detail",
        CONFIRM_BUT: ChargeDetailConstant.Charge.CONFIRM_BUT,
        UNCONFIRM_BUT: "UnConfirm",
        DELETE_BUT: "Delete",
        RE_PRINT_BUT: "Reprint",
        ADD_BUT: "Add",
        EDIT_TITLE: "EDIT",
        EDIT_BUT: "EDIT",
        APPLY_BUT: "Apply",
        APPLY_ALL_BUT: "Apply All",
        
    },
    Search: {
        CREATED_DATE_RANGE: "Created Date Range",
        CREATED_DATE_FROM: "Created Date (From)",
        CREATED_DATE_TO: "Created Date (To)",
        SEARCH_CRITERIA: ChargeDetailConstant.Charge.SEARCH_CRITERIA,
        CO_VSL_VOY: ChargeDetailConstant.Charge.CO_VSL_VOY,
        REQ_NO_FROM: "Req. No. From",
        REQ_NO_TO: "Req. No. To",
        CREDIT_CHARGE_NO_FROM: "Credit Charge No.(Fr)",
        CREDIT_CHARGE_NO_TO: "Credit Charge No.(To)",

    },
    Detail: {
        STATE: "State",
        CREDIT_DETAIL_NO: "Credit Detail No.",
        INVOICE_NO: "Invoice No",
        ORIG_CHARGING_QTY: "Original Charging Qty",
        ORIG_AMOUNT: "Original Amount",
        REF_CHARGE_AMOUNT: "Ref Charge Amount",
        CREDIT_CHARGING_QTY: "Credit Charging Qty",
        CHARGE_UOM: ChargeDetailConstant.Charge.CHARGE_QTY_UOM,
        GET_UOM: "Get UOM",
        REBATE: "Rebate",
        UPT_COA: "Update COA",
        UPT_TAX_COA: "Update Tax COA",
        CREDIT_AMOUNT: "Credit Amount",
        TAR_TYPE: "Tar Type",
        TAR_CODE: "Tar Code",
        TAR_DESC: "Tar Desc",
        DETAIL_DESC1: "Detail Desc1",
        DETAIL_DESC2: "Detail Desc2",
        OPS_DATE: ChargeDetailConstant.Charge.OPS_DATE,
        ADJ_TYPE: ChargeDetailConstant.Charge.ADJ_TYPE,
        DOC_TYPE: "Doc. Type",
        CREDIT_NOTE_NATURE: "Credite Note Nature",
        REVENUE_AC_CODE: "Revenue AC Code",
        COST_CENTER_CODE: "Cost Center Code",
        PRODUCT_SERVICE_CODE: "Product Serivce Code",
        SALES_CHANNEL_CODE: "Sales Channel Code",
        COUNTRY_CODE: "Country Code",
        INTER_COMPANY_CODE: "Inter Company Code",
        PROJECT_CODE: "Project Code",
        SPARE_CODE: "Spare Code",
        REVENUE_COMPANY_CODE: "Company Code",
        TAX_CODE: "Tax Code",
        TAX_PENCENTAGE: "Tax %",
        TAX_REVENUE_AC_CODE: "Tax Revenue A/C Code",
        TAX_COST_CENTER_CODE: "Tax Cost Center Code",
        TAX_PRODUCT_SERVICE_CODE: "Tax Product Serivce Code",
        TAX_SALES_CHANNEL_CODE: "Tax Sales Channel Code",
        TAX_COUNTRY_CODE: "Tax Country Code",
        TAX_INTER_COMPANY_CODE: "Tax Inter-company Code",
        TAX_PROJECT_CODE: "Tax Project Code",
        TAX_SPARE_CODE: "Tax Spare Code",
        TAX_COMPANY_CODE: "Tax Company Code",
        TRANSACTION_TYPE: "Transaction Type",
        TAX_PAYMENT_TERM: "Tax Payment Term",
        TAX_PAYMENT_REFERENCE_DATE: "Tax Payment Reference Date",
        ARTICLE_STATEMENT_CODE: "Article Statement Code",
        ARTICLE_STATEMENT: "Article Statement",
        TAX_DETAIL_DESC1: "Tax Detail Desc 1",
        TAX_DETAIL_DESC2: "Tax Detail Desc 2",
        TAX_TARIFF_TYPE: "Tax Tariff Type",
        TAX_TARIFF_CODE: "Tax Tariff Code",
        TAX_TARIFF_CODE_DESC: "Tax Tariff Code Desc.",
        CREDIT_NOTE_NO: "Credit Note No.",
        CHARGE_IND: ChargeDetailConstant.Charge.CHARGE_IND,
        REASON_CODE: ChargeDetailConstant.Charge.REASON_CODE,
        EXCEPTION_MSG: "Exception Msg",
        
    },
    Invoice: {
        PREVIEWREF_NO: "Preview Ref. No.",
        DOC_TYPE: "Doc. Type",
        DOC_NO: "Invoice No",
        DOC_ITEM_LIST: "Invoice Item List",
        TARIFF_ITEM_LIST: "Tariff Item List",
        HANDLING_TML: "Handling Tml.",
        SERVICE_CODE: "Service Code",
        BILL_TO_COMPANY: "Bill To Company",
        CUSTOMER_CODE: "Customer Code",
        CUSTOMER_NAME1: "Customer Name 1",
        CUSTOMER_NAME2: "Customer Name 2",
        OPS_DATE: "Ops. Date",
        CHARGE_TYPE: "Charge Type",
        CONSORTIUM_CODE: "Consortium Code",
        VESSEL_CODE: "Vessel Code",
        VOYAGE_CODE: "Voyage Code",
        VESSEL_NAME: "Vessel Name",
        DEP_DATE_TIME: "Dep Date/Time",
        ERROR_MESSAGE: "Error Message",
        GENERATION_BY: "Generated by",
        GENERATION_DATE: "Generation Date",
        CONTRACT_CURRENCY: "Contract Currency",
        BILLING_TYPE: "Billing Type",
        BILLING_CYCLE_CODE: "Billing Cycle Code",

    },
    Print: {
        TITLE: "Print Credit Note",
        BILL_TO_COMPANY: "Bill To Company",
        REQ_NO_FROM: "Req. No. From",
        REQ_NO_TO: "Req. No. to",
        CREDIT_NOTE_NO_FROM: "Credit Note No. From",
        CREDIT_NOTE_NO_TO: "Credit Note No. To",
        CREDIT_CHARGE_NO_FROM: "Credit Charge No.(Fr)",
        CREDIT_CHARGE_NO_TO: "Credit Charge No.(To)",
        PRINTER: "Printer"
    }


}